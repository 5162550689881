

import Select from './src/Select.vue'
import { App } from 'vue'


Select.install = (app: App) => {
    app.component(Select.name, Select)
}

export default Select
